import React from "react";

import { Button } from "../Button";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  SubTitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./styles";
import Vantagens from "../Vantagens";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topline,
  lightText,
  headline,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  displayVantagensComponents,
}) => {
  return (
    <>
      <div id={id}>
        <InfoContainer lightBg={lightBg} id={id}>
          <InfoWrapper>
            <InfoRow imgStart={imgStart}>
              <Column1>
                <TextWrapper>
                  <TopLine>{topline}</TopLine>
                  <Heading lightText={lightText}>{headline}</Heading>
                  <div>
                    {description.map((item, key) => {
                      return (
                        <SubTitle key={key} darkText={darkText}>
                          {item.desc}
                        </SubTitle>
                      );
                    })}
                  </div>
                  <BtnWrap>
                    <Button
                      href="https://api.whatsapp.com/send?phone=5521998043582"
                      target="_blank"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact={true}
                      offset={-80}
                      primary={primary ? 1 : 0}
                      dark={dark ? 1 : 0}
                      dark2={dark2 ? 1 : 0}
                    >
                      {buttonLabel}
                    </Button>
                  </BtnWrap>
                </TextWrapper>
              </Column1>
              <Column2>
                <ImgWrap>
                  <Img src={img} alt={alt} />
                </ImgWrap>
              </Column2>
            </InfoRow>
          </InfoWrapper>
        </InfoContainer>
        <Vantagens
          displayVantagensComponents={displayVantagensComponents ? 1 : 0}
        />
      </div>
    </>
  );
};

export default InfoSection;
